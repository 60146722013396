<template>
  <div class="popup_wrap" style="width:1050px;">
    <button class="layer_close" v-show="!popupComponent" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONEX020P061.001') }}</h1> <!-- Booking Cancel Fee 정산 -->
      <div class="content_box">
        <!-- content_box -->
        <table class="tbl_col">
          <colgroup>
            <col width="6%">
            <col width="10%">
            <col width="10%">
            <col width="8%">
            <col width="8%">
            <col width="20%" v-if="viewModeYn === 'N'">
            <col width="10%" v-if="viewModeYn === 'N'">
            <col width="15%" v-if="viewModeYn === 'Y'">
            <col width="15%" v-if="viewModeYn === 'Y'">
            <col width="8%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONEX020P061.002') }}</th> <!-- Kind -->
              <th>{{ $t('msg.ONEX020P061.003') }}</th> <!-- Booking No. -->
              <th>{{ $t('msg.ONEX020P061.004') }}</th> <!-- CNTR No. -->
              <th>{{ $t('msg.ONEX020P061.005') }}</th> <!-- Size -->
              <th>{{ $t('msg.ONEX020P061.006') }}</th> <!-- Type -->
              <th>{{ $t('msg.ONEX020P061.007') }}</th> <!-- 픽업일 -->
              <th>{{ $t('msg.ONEX020P061.008') }}</th> <!-- 반납예정일 -->
              <th>{{ $t('msg.ONEX020P061.009') }}</th> <!-- Over Day -->
              <th>{{ $t('msg.ONEX020P061.010') }}</th> <!-- DET AMT -->
              <th>{{ $t('msg.ONEX020P061.011') }}</th> <!-- VAT -->
              <th>{{ $t('msg.ONEX020P061.012') }}</th> <!-- G.TTL -->
            </tr>
          </thead>
          <tbody v-if="viewModeYn === 'N'">
            <tr v-for="(container, index) in containerList" :key="index">
              <td>{{ $t('msg.ONEX020P061.013') }}</td>
              <td>{{ parentInfo.bkgNo }}</td>
              <td>{{ container.cntrNo }}</td>
              <td>{{ container.cntrSzCd }}</td>
              <td>{{ container.cntrTypCd }}</td>
              <td>{{ container.pcupReqDt }}</td>
              <td>
                <e-date-picker v-model="container.returnDate" @input="onInput(index)" :id="'returnDate_' + index" />
              </td>
              <td>{{ $ekmtcCommon.changeNumberFormat(container.overDay, { isComma: true }) }}<template v-if="container.overDay">{{ $t('msg.ONEX020P061.014') }}</template></td>
              <td>{{ $ekmtcCommon.changeNumberFormat(container.det, { isComma: true }) }} <template v-if="container.det">({{ container.curCd }})</template></td>
              <td>{{ $ekmtcCommon.changeNumberFormat((container.det * 0.1), { isComma: true }) }} <template v-if="container.det">({{ container.curCd }})</template></td>
              <td>{{ $ekmtcCommon.changeNumberFormat((container.det + (container.det * 0.1)), { isComma: true }) }} <template v-if="container.det">({{ container.curCd }})</template></td>
            </tr>
          </tbody>
          <tbody v-if="viewModeYn === 'Y'">
            <tr v-for="(vo, index) in nonFreightList" :key="index">
              <td>{{ $t('msg.ONEX020P061.013') }}</td>
              <td>{{ vo.bkgNo }}</td>
              <td>{{ vo.cntrNo }}</td>
              <td>{{ vo.cntrSzCd }}</td>
              <td>{{ vo.cntrTypCd }}</td>
              <td>{{ getNewDateFormat(vo.gateStrDt) }}</td>
              <td>{{ getNewDateFormat(vo.gateEndDt) }}</td>
              <td>{{ $ekmtcCommon.changeNumberFormat(vo.ovrDds, { isComma: true }) }}<template v-if="vo.ovrDds">{{ $t('msg.ONEX020P061.014') }}</template></td>
              <td>{{ $ekmtcCommon.changeNumberFormat(vo.locAmt, { isComma: true }) }} <template v-if="vo.locAmt">({{ vo.curCd }})</template></td>
              <td>{{ $ekmtcCommon.changeNumberFormat(vo.invTaxAmt, { isComma: true }) }} <template v-if="vo.invTaxAmt">({{ vo.curCd }})</template></td>
              <td>{{ $ekmtcCommon.changeNumberFormat((vo.locAmt + vo.invTaxAmt), { isComma: true }) }} <template v-if="vo.det">({{ vo.curCd }})</template></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" @click.prevent="fnIssue()">{{ $t('msg.ONEX020P061.015') }}</a> <!-- Invoice 발행/출력 -->
        <a class="button blue lg" v-if="viewModeYn === 'Y'" @click.prevent="fnFreightPop()">{{ $t('msg.ONEX020P061.016') }}</a> <!-- 운임수납 -->
        <a class="button gray lg" @click.prevent="$emit('close')">{{ $t('msg.ONEX020P061.017') }}</a> <!-- 닫기 -->
      </div>
    </div>
    <win-layer-pop class="booking-cancel-fee-pop">
      <component
        v-if="popupComponent"
        :is="popupComponent"
        @close="closePopup"
        :parent-info="popupParams"
        :freight-bill-info="freightBillInfo"
      />
    </win-layer-pop>
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import demDetTariff from '@/api/rest/trans/demDetTariff'
import EDatePicker from '@/components/common/EDatePicker'
import bookings from '@/api/rest/trans/bookings'
import nonFreight from '@/api/rest/settle/nonFreight'
import InputTaxInvoiceInfo from '@/pages/settle/popup/InputTaxInvoiceInfo'

export default {
  name: 'BookingCancelFeePop',
  components: {
    EDatePicker,
    InputTaxInvoiceInfo,
    DemInvoicePop: () => import('@/pages/settle/popup/DemInvoicePop'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: '',
          ctrCd: '',
          eiCatCd: 'O'
        }
      }
    }
  },
  data () {
    return {
      bkgNo: '',
      containerList: [],
      nonFreightList: [],
      viewModeYn: 'N',
      formData: {
        ctrCd: '',
        demDetCatCd: '',
        portCd: '',
        eiCatCd: 'O'
      },
      popupComponent: null,
      popupParams: {},
      freightBillInfo: {}
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.bkgNo = this.parentInfo.bkgNo
    this.init()
  },
  methods: {
    async init () {
      await this.getNonFreightBooking()
      if (this.viewModeYn === 'N') {
        await this.getContainerLog()
      }
      // await Promise.all([
      //   this.getContainerLog(),
      //   this.getNonFreightBooking()
      // ])
    },
    async getContainerLog () {
      const result = await bookings.getContainerLog({ bkgNo: this.bkgNo, cntrStsCd: 'GTO' })

      if (result.data.length > 0) {
        this.containerList = result.data.map((container) => {
          container.pcupReqDt = this.getNewDateFormat(container.mvntDt)
          container.overDay = 0
          container.det = 0
          container.vat = 0
          container.curCd = ''
          return container
        })
      }
    },
    async getNonFreightBooking () {
      await nonFreight.getNonFreightBooking({ bkgNo: this.bkgNo }).then(async result => {
        if (result.data.length > 0) {
          this.nonFreightList = result.data
          this.viewModeYn = 'Y'
        } else {
          this.nonFreightList = []
          this.viewModeYn = 'N'
        }
      })
    },
    fnFreightPop () {
      let bookingInfo = this.nonFreightList.length > 0 ? this.nonFreightList[0] : null

      if (bookingInfo !== null) {
        //let cntrList = [{ cntrNo: bookingInfo.cntrNo }]
        let cntrList = []
        for (let f of this.nonFreightList) {
          cntrList.push(
            {
              cntrNo: f.cntrNo,
              extsDt: f.gateStrDt,
              freeDds: f.freeDds,
              ovrDds: f.ovrDds,
              totAmt: parseInt(f.locAmt) + parseInt(f.invTaxAmt),
              locAmt: f.locAmt,
              dcAmt: 0,
              cfmYn: 'N',
              dcRt: 0,
              cntrSzCd: f.cntrSzCd,
              cntrTypCd: f.cntrTypCd
            }
          )
          // let obj = {
          //   cntrNo: f.cntrNo,
          //   extsDt: '',
          //   freeDds: 0,
          //   ovrDds: f.ovrDds,
          //   totAmt: parseInt(f.locAmt) + parseInt(f.invTaxAmt),
          //   dcAmt: 0,
          //   cfmYn: 'N',
          //   dcRt: 0,
          //   cntrSzCd: f.cntrSzCd,
          //   cntrTypCd: f.cntrTypCd
          // }
        }
        let info = {
          dpId: '',
          dpType: 'B',
          isShow: {
            info: false,
            method: true,
            tax: true
          },
          blList: [{
            blNo: bookingInfo.blNo,
            krw: parseInt(bookingInfo.locAmt) + parseInt(bookingInfo.invTaxAmt),
            usd: 0,
            exRate: 1136, //TODO
            currency: 'KRW',
            obrdDt: '', //TODO
            demDetCatCd: '07', // 07 CANCEL
            eiCatCd: 'O', //bookingInfo.eiCatCd,
            cntrList: cntrList//this.nonFreightList//cntrList
          }],
          bookingInfo: bookingInfo,
          btnType: 'BOOKINGCANCEL'
        }
        console.log('cntrList============================================================================', cntrList)
        console.log('cntrList============================================================================', this.nonFreightList)
        this.freightBillInfo = info
        this.popupComponent = 'InputTaxInvoiceInfo'
        this.$ekmtcCommon.layerOpen('.booking-cancel-fee-pop')
      }
    },
    //반납예정일 변경시
    onInput (index) {
      if (this.validationCheck(index)) {
        this.calculate(index)
      }
    },
    //벨리데이션
    validationCheck (index) {
      let isOk = true

      const selector = document.querySelector('#returnDate_' + index)
      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (selector.value === '') {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147')) // 필수 입력 항목입니다.
        isOk = false
        return isOk
      }

      const pickUpDate = new Date(this.containerList[index].pcupReqDt).setHours(0, 0, 0, 0) //픽업일
      const returnDate = new Date(selector.value).setHours(0, 0, 0, 0) //반납예정일

      if (pickUpDate > returnDate) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P110.018')) //반납예정일은 픽업일보다 빠를 수 없습니다.
        isOk = false
      }
      return isOk
    },
    //계산로직
    async calculate (index) {
      //1.파라미터셋팅
      const targetContainer = this.containerList[index]
      this.formData.ctrCd = targetContainer.ctrCd
      this.formData.portCd = targetContainer.plcCd

      //2.DET 계산 테이블 데이터 로딩
      this.formData.demDetCatCd = '02'
      const result = await demDetTariff.getDemDetTariffInfoList(this.formData)

      //3.계산 타겟 테이블로 계산 테이블 데이터 필터링
      const detDataList = result.data.demDetCalculatorList

      //4.오버데이 셋팅
      const selector = document.querySelector('#returnDate_' + index)
      const returnDate = new Date(selector.value).setHours(0, 0, 0, 0) //반납예정일
      const pickUpDate = new Date(this.containerList[index].pcupReqDt).setHours(0, 0, 0, 0) //픽업일
      targetContainer.overDay = (returnDate - pickUpDate) <= 0 ? 0 : ((returnDate - pickUpDate) / (1000 * 3600 * 24)) + 1

      if (targetContainer.overDay === 0) {
        targetContainer.overDay = 0
        targetContainer.det = 0
        targetContainer.vat = 0
        targetContainer.curCd = ''
        this.$set(this.containerList, index, targetContainer)

        return
      }

      //5.타겟컨테이너와 필터링된 계산 테이블로 계산 시작
      const filteredData = detDataList.filter((item) => {
        if (targetContainer.cgoTypCd) {
          return item.cntrTypeCd === targetContainer.cntrTypCd && (item.cgoTypCd === targetContainer.cgoTypCd)
        } else {
          return item.cntrTypCd === targetContainer.cntrTypCd && !item.cgoTypCd
        }
      })[0]

      if (!filteredData) {
         this.$ekmtcCommon.asyncAlertMessage({ message: '계산을 위한 참고데이터가 없습니다.' })
         return
      }

      filteredData.size = targetContainer.cntrSzCd
      filteredData.overDay = targetContainer.overDay

      let total = 0
      //RATE1 계산
      // console.log('rate1')
      const rate1 = this.calculateOverRate(filteredData, 1)
      total += rate1.total
      if (rate1.overFlag === false) {
        //RATE2 계산
        // console.log('rate2')
        const rate2 = this.calculateOverRate(filteredData, 2)
        total += rate2.total
        if (rate2.overFlag === false) {
          //RATE3 계산
          // console.log('rate3')
          const rate3 = this.calculateOverRate(filteredData, 3)
          if (rate3.overFlag === false) {
            //RATE4 계산
            // console.log('rate4')
            const rate4 = this.calculateOverRate(filteredData, 4)
            total += rate4.total
          }
        }
      }

      //6.set
      targetContainer.det = total
      targetContainer.curCd = filteredData.curCd
      this.$set(this.containerList, index, targetContainer)
    },
    //오버레이트 계산
    calculateOverRate (filteredData, stage) {
      let overFlag = false
      //UNTIL 일수 계산
      let until = 0
      let untilRate = 0
      let size = parseInt(filteredData.size)

      if (stage === 1) {
        until = parseInt(filteredData.trf1Dds)
        if (size === 20) {
          untilRate = parseInt(filteredData.trf1Rate20)
        } else if (size === 40) {
          untilRate = parseInt(filteredData.trf1Rate40)
        } else {
          untilRate = parseInt(filteredData.trf1Rate45)
        }
      } else if (stage === 2) {
        until = parseInt(filteredData.trf2Dds)
        if (size === 20) {
          untilRate = parseInt(filteredData.trf2Rate20)
        } else if (size === 40) {
          untilRate = parseInt(filteredData.trf2Rate40)
        } else {
          untilRate = parseInt(filteredData.trf2Rate45)
        }
      } else if (stage === 3) {
        until = parseInt(filteredData.trf3Dds)
        if (size === 20) {
          untilRate = parseInt(filteredData.trf3Rate20)
        } else if (size === 40) {
          untilRate = parseInt(filteredData.trf3Rate40)
        } else {
          untilRate = parseInt(filteredData.trf3Rate45)
        }
      } else {
        until = parseInt(filteredData.trf4Dds)
        if (size === 20) {
          untilRate = parseInt(filteredData.trf4Rate20)
        } else if (size === 40) {
          untilRate = parseInt(filteredData.trf4Rate40)
        } else {
          untilRate = parseInt(filteredData.trf4Rate45)
        }
      }

      let start = 0
      if (stage === 1) {
        start = 1
      } else if (stage === 2) {
        start = 1 + parseInt(filteredData.trf1Dds)
      } else if (stage === 3) {
        start = 1 + parseInt(filteredData.trf2Dds)
      } else if (stage === 4) {
        start = 1 + parseInt(filteredData.trf3Dds)
      }

      until = until === 0 ? 9999 : until

      let total = 0
      for (let i = start; i <= until; i++) {
        total += untilRate

        if (i === filteredData.overDay) {
          overFlag = true
          break
        }
      }

      return { total: total, overFlag: overFlag }
    },
    getNewDateFormat (beforeDate) {
      let year = beforeDate.substring(0, 4)
      let month = beforeDate.substring(4, 6)
      let day = beforeDate.substring(6, 8)
      return year + '-' + month + '-' + day
    },
    async fnIssue () {
      if (this.viewModeYn === 'N') {
        this.insertNonFreight()
      } else {
        this.fnInvoicePop('')
      }
    },
    async fnInvoicePop (type) {
      if (type === 'SAVE_END') {
        await this.getNonFreightBooking()
      }

      if (this.viewModeYn === 'N') {
        return
      }

      this.popupParams = {
        invoice: this.nonFreightList[0],
        blNo: this.nonFreightList[0].blNo,
        lang: this.auth.serviceLang,
        usrNm: '',
        ctrCd: this.memberDetail.userCtrCd,
        demDetCatCd: '02',
        eiCatCd: 'O'
      }
      this.openPopup('DemInvoicePop')
    },
    insertNonFreight () {
      let len = this.containerList.length
      let isValid = true

      for (let i = 0; i < len; i++) {
        if (!this.validationCheck(i)) {
          isValid = false
        }
      }

      if (!isValid) {
        return
      }

      const items = []

      this.containerList.forEach((vo, idx) => {
        items.push({
          demDetCatCd: '07', // 02: DETENTION, 07: CANCEL
          bkgNo: vo.bkgNo,
          eiCatCd: 'O', // 수출: O, 수입: I
          demSeq: (idx + 1),
          cntrNo: vo.cntrNo,
          cntrSzCd: vo.cntrSzCd,
          cntrTypCd: vo.cntrTypCd,
          cntrQty: '1',
          gateStrDt: vo.pcupReqDt.replaceAll('-', ''),
          gateEndDt: vo.returnDate,
          freeDds: 0,
          ovrDds: vo.overDay,
          curCd: vo.curCd,
          totAmt: vo.det,
          locAmt: vo.det,
          exrt: 1,
          rmk: ''
        })
      })

      const params = {
        bkgNo: this.bkgNo,
        invKndCd: '02', // 02: DETENTION
        payPlcCd: 'SEL',
        items: items
      }

      nonFreight.insertNonFreight(params).then(res => {
        if (res.headers.respcode === 'C0000') {
          this.fnInvoicePop('SAVE_END')
        } else {
          let msg = this.$t(res.headers.respmsg)
          if (msg === undefined || msg === '') {
            msg = 'Non-Freight 등록 실패'
          }
          this.$ekmtcCommon.alert({
            alertType: 'simple',
            customCloseBtnText: this.$t('msg.NEWB010150.023'), // 확인
            message: msg
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    openPopup (compNm) {
      this.popupComponent = compNm
      this.$ekmtcCommon.layerOpen('.booking-cancel-fee-pop')
    },
    closePopup () {
      this.popupComponent = null
      this.$ekmtcCommon.layerClose('.booking-cancel-fee-pop')
    }
  }
}
</script>
