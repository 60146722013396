import Send from '../../settle.client.js'

export default {
  insertNonFreight (data) {
    return Send({
      url: '/settle/non-freight',
      method: 'post',
      data: data
    })
  },
  getNonFreightBooking (params) {
    return Send({
      url: '/settle/non-freight/booking/' + params.bkgNo,
      method: 'get'
    })
  }
}
