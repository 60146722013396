var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1050px" } },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.popupComponent,
              expression: "!popupComponent",
            },
          ],
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX020P061.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _c("colgroup", [
              _c("col", { attrs: { width: "6%" } }),
              _c("col", { attrs: { width: "10%" } }),
              _c("col", { attrs: { width: "10%" } }),
              _c("col", { attrs: { width: "8%" } }),
              _c("col", { attrs: { width: "8%" } }),
              _vm.viewModeYn === "N"
                ? _c("col", { attrs: { width: "20%" } })
                : _vm._e(),
              _vm.viewModeYn === "N"
                ? _c("col", { attrs: { width: "10%" } })
                : _vm._e(),
              _vm.viewModeYn === "Y"
                ? _c("col", { attrs: { width: "15%" } })
                : _vm._e(),
              _vm.viewModeYn === "Y"
                ? _c("col", { attrs: { width: "15%" } })
                : _vm._e(),
              _c("col", { attrs: { width: "8%" } }),
              _c("col", { attrs: { width: "10%" } }),
              _c("col", { attrs: { width: "10%" } }),
              _c("col", { attrs: { width: "10%" } }),
            ]),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.002")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.003")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.004")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.005")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.006")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.007")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.008")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.009")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.010")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.011")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.012")))]),
              ]),
            ]),
            _vm.viewModeYn === "N"
              ? _c(
                  "tbody",
                  _vm._l(_vm.containerList, function (container, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.013")))]),
                      _c("td", [_vm._v(_vm._s(_vm.parentInfo.bkgNo))]),
                      _c("td", [_vm._v(_vm._s(container.cntrNo))]),
                      _c("td", [_vm._v(_vm._s(container.cntrSzCd))]),
                      _c("td", [_vm._v(_vm._s(container.cntrTypCd))]),
                      _c("td", [_vm._v(_vm._s(container.pcupReqDt))]),
                      _c(
                        "td",
                        [
                          _c("e-date-picker", {
                            attrs: { id: "returnDate_" + index },
                            on: {
                              input: function ($event) {
                                return _vm.onInput(index)
                              },
                            },
                            model: {
                              value: container.returnDate,
                              callback: function ($$v) {
                                _vm.$set(container, "returnDate", $$v)
                              },
                              expression: "container.returnDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(
                                container.overDay,
                                { isComma: true }
                              )
                            )
                          ),
                          container.overDay
                            ? [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.014")))]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(
                                container.det,
                                { isComma: true }
                              )
                            ) + " "
                          ),
                          container.det
                            ? [_vm._v("(" + _vm._s(container.curCd) + ")")]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(
                                container.det * 0.1,
                                { isComma: true }
                              )
                            ) + " "
                          ),
                          container.det
                            ? [_vm._v("(" + _vm._s(container.curCd) + ")")]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(
                                container.det + container.det * 0.1,
                                { isComma: true }
                              )
                            ) + " "
                          ),
                          container.det
                            ? [_vm._v("(" + _vm._s(container.curCd) + ")")]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.viewModeYn === "Y"
              ? _c(
                  "tbody",
                  _vm._l(_vm.nonFreightList, function (vo, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.013")))]),
                      _c("td", [_vm._v(_vm._s(vo.bkgNo))]),
                      _c("td", [_vm._v(_vm._s(vo.cntrNo))]),
                      _c("td", [_vm._v(_vm._s(vo.cntrSzCd))]),
                      _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.getNewDateFormat(vo.gateStrDt))),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.getNewDateFormat(vo.gateEndDt))),
                      ]),
                      _c(
                        "td",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(vo.ovrDds, {
                                isComma: true,
                              })
                            )
                          ),
                          vo.ovrDds
                            ? [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.014")))]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(vo.locAmt, {
                                isComma: true,
                              })
                            ) + " "
                          ),
                          vo.locAmt
                            ? [_vm._v("(" + _vm._s(vo.curCd) + ")")]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(
                                vo.invTaxAmt,
                                { isComma: true }
                              )
                            ) + " "
                          ),
                          vo.invTaxAmt
                            ? [_vm._v("(" + _vm._s(vo.curCd) + ")")]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeNumberFormat(
                                vo.locAmt + vo.invTaxAmt,
                                { isComma: true }
                              )
                            ) + " "
                          ),
                          vo.det
                            ? [_vm._v("(" + _vm._s(vo.curCd) + ")")]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.fnIssue()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.015")))]
          ),
          _vm._v(" "),
          _vm.viewModeYn === "Y"
            ? _c(
                "a",
                {
                  staticClass: "button blue lg",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.fnFreightPop()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.016")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.017")))]
          ),
        ]),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "booking-cancel-fee-pop" },
        [
          _vm.popupComponent
            ? _c(_vm.popupComponent, {
                tag: "component",
                attrs: {
                  "parent-info": _vm.popupParams,
                  "freight-bill-info": _vm.freightBillInfo,
                },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }